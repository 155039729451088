export function getTokenExpirationTimestamp(token: string): number {
    const decoded = JSON.parse(atob(token.split(".")[1]));
    return (decoded.exp as number) * 1000;
}

export function isTokenExpired(token: string): boolean {
    const expirationTimestamp = getTokenExpirationTimestamp(token);
    // todo: there is always a risk that device's time setting is incorrect. in that case we may be caught
    //  in an infinite redirect loop - must be handled
    const now = new Date().getTime();
    return now > expirationTimestamp;
}
