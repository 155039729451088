import { FunctionalComponent, h } from "preact";
import { Route, Router } from "preact-router";

import AuthCallbackPage from "../AuthCallbackPage";
import MainPage from "../MainPage";

import * as styles from "./styles.css";
import ErrorPage from "../ErrorPage";

const App: FunctionalComponent = () => {
    return (
        <div className={styles.appWrapper}>
            {/*<h1>App Container</h1>*/}
            <Router>
                <Route path="/auth/:region/:embedId/callback" component={AuthCallbackPage} />
                <Route path="/:region/:embedId" component={MainPage} />
                <ErrorPage title="404" topic="404" message="Page not found." default />
            </Router>
        </div>
    );
};
export default App;
