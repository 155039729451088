// localStorage.debug = 'embed:*' // for all namespaces
// localStorage.debug = 'embed:main, embed:ui' // by namespaces

import debug from "debug";

export const LOG_PREFIX = "embed";

export function getLogger(namespace: string): debug.Debugger {
    return debug(LOG_PREFIX).extend(namespace);
}

export const isDebugEnable = (namespace: string): boolean => {
    return debug.enabled(`${LOG_PREFIX}:${namespace}`);
};
