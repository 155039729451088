type ObserveWindowClosedParams = {
    window: Window | null;
    timeoutInSec: number;
    callback: () => void;
};

export function observeWindowClosed({
    window,
    timeoutInSec,
    callback
}: ObserveWindowClosedParams): void {
    let count = 0;
    const timer = setInterval(() => {
        ++count;
        if (window?.closed) {
            clearInterval(timer);
            callback();
        }
        if (count > timeoutInSec) {
            clearInterval(timer);
            if (window) {
                window.close();
                window = null;
            }
        }
    }, 1000); // check every 1s
}

export type WindowParams = {
    id: string;
    url: string;
    callback: () => void;
    originX?: number;
    originY?: number;
    windowWidth?: string;
    windowHeight?: string;
    openCenter?: boolean;
    observeWindowClosedTimeout?: number;
};

export function openWindow({
    id,
    url,
    callback,
    openCenter,
    originX = 0,
    originY = 100,
    windowWidth = "400px",
    windowHeight = "750px",
    observeWindowClosedTimeout = 300
}: WindowParams): void {
    if (openCenter) {
        originX = (window.screen.width - parseInt(windowWidth)) / 2;
        originY = (window.screen.height - parseInt(windowHeight)) / 2;
    }
    const windowParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=${windowWidth},height=${windowHeight},left=${originX},top=${originY}`;
    const childWindow = window.open(url, id, windowParams);
    const params: ObserveWindowClosedParams = {
        window: childWindow,
        timeoutInSec: observeWindowClosedTimeout,
        callback
    };
    observeWindowClosed(params);
}
