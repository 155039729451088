import { RootAction, RootState } from "./types";
import { updateEmbedInfoActionType } from "./actions";

export const initialState: RootState = {
    embedInfo: null
};

function reducer(state: RootState, action: RootAction): RootState {
    switch (action.type) {
        case updateEmbedInfoActionType:
            return {
                ...state,
                embedInfo: {
                    ...state.embedInfo,
                    ...action.payload
                }
            };
        // case updateTokenActionType:
        //     return {
        //         ...state,
        //         overrideTokens: { ...state.overrideTokens, ...action.payload }
        //     };
        default:
            return state;
    }
}

export default reducer;
