import { h } from "preact";
import { useReducer } from "preact/hooks";

import rootContext from "./state/context";
import { RootAction, RootState } from "./state/types";
import reducer, { initialState } from "./state/reducer";

import App from "./components/App";

import "./assets/styles/global.css";

const { Provider } = rootContext;

export default function Index(): JSX.Element {
    const [state, dispatch] = useReducer<RootState, RootAction>(reducer, initialState);
    return (
        <Provider value={{ ...state, dispatch }}>
            <App />
        </Provider>
    );
}
