import config, { Region } from "../../config";
import { useCallback, useEffect, useState } from "preact/hooks";
import { FirestoreClient } from "../../libs/signage-firestore-client/FirestoreClient";
import { EntityType } from "../../libs/signage-firestore-client/types";
import { ApiError, EmbedInfoResponse } from "../../api/types";
import { getEmbedInfoFromServer } from "../../api";
import { clientEmbedViewerId, clientSessionId } from "../../classes/clientIdsManager";
import useApiRequest from "../../state/hooks/useApiRequest";
import { getTokenExpirationTimestamp } from "../../utils/token";

export const useSetupLiveUpdate = (
    region: Region,
    reloadCallback: () => void,
    orgId?: string,
    contentType?: string,
    contentId?: string
): void => {
    const [firestoreClient, setFirestoreClient] = useState<{
        client: FirestoreClient;
        region: Region;
        orgId: string;
    } | null>(null);

    useEffect(() => {
        let isDismissed = false;
        if (orgId && (!firestoreClient || firestoreClient.orgId !== orgId)) {
            const setClient = () => {
                const client = new FirestoreClient({
                    firebase: config.firebase[region],
                    organizationId: orgId
                });
                setFirestoreClient({
                    client,
                    region,
                    orgId: orgId
                });
            };

            if (firestoreClient?.client && !firestoreClient.client.isPurged()) {
                firestoreClient.client.purge()?.then(() => {
                    if (isDismissed) {
                        return;
                    }
                    setClient();
                });
            } else {
                setClient();
            }
        }

        return (): void => {
            // if (firestoreClient?.client) {
            //     firestoreClient.client.purge();
            // }
            isDismissed = true;
        };
    }, [orgId, region, firestoreClient]);

    useEffect(() => {
        if (contentType && contentId && firestoreClient?.client) {
            const type = contentType as EntityType;
            const id = contentId;
            firestoreClient.client.subscribeToEntityChange(type, id, reloadCallback, true);

            return (): void => {
                firestoreClient.client.unsubscribeFromEntityChange(type, id);
            };
        }
    }, [orgId, region, contentType, contentId, firestoreClient, reloadCallback]);
};

export const useGetEmbedInformation = (
    embedId: string,
    region: Region = "eu"
): [ApiError | undefined, EmbedInfoResponse | undefined, () => void] => {
    const embedInfoRequest = useCallback(
        () => getEmbedInfoFromServer(embedId, region, clientEmbedViewerId(), clientSessionId()),
        [embedId, region]
    );
    const {
        response: { data, error },
        reload
    } = useApiRequest(embedInfoRequest);
    // const { response, reload } = useApiRequest(embedInfoRequest);
    return [error, data, reload];
};

export const useSetupAutoReloadTokenWhenExpired = (
    data: EmbedInfoResponse | undefined,
    reload: () => void
): void => {
    useEffect(() => {
        if (data?.isEmbedEnabled && data.isEmbedPublic && data.token) {
            const tokenExpirationTimestamp = getTokenExpirationTimestamp(data.token);
            const timeoutValue = tokenExpirationTimestamp - new Date().getTime();

            if (timeoutValue > 0) {
                const timeout = window.setTimeout(() => {
                    reload();
                }, timeoutValue);

                return (): void => {
                    window.clearTimeout(timeout);
                };
            }
        }
    }, [data, reload]);
};
