import { UrlParams } from "../constants/params";

type QueryStringCollection = { [key: string]: string | undefined };

export const getQueryStringCollection = (): QueryStringCollection => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const collection: QueryStringCollection = {};
    Object.keys(UrlParams).forEach((key: string) => {
        collection[UrlParams[key]] = urlParams.get(UrlParams[key]) || undefined;
    });
    return collection;
};
