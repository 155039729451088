import { get, remove, set } from "./storeManager";
import { isTokenExpired } from "../utils/token";

function getStudioTokenKey(orgId: string): string {
    return `embed_${orgId}_studio_token`;
}

function getEmbedTokenKey(contentId: string): string {
    return `embed_${contentId}_embed_token`;
}

export function removeStudioToken(orgId: string): void {
    remove(getStudioTokenKey(orgId));
}

export function removeEmbedToken(contentId: string): void {
    remove(getEmbedTokenKey(contentId));
}

export function getStudioToken(orgId: string): string | undefined {
    const token = get(getStudioTokenKey(orgId));

    if (token) {
        try {
            if (isTokenExpired(token)) {
                throw new Error("token is expired");
            }
            return token;
        } catch (err) {
            console.error(err.message);
            removeStudioToken(orgId);
            return undefined;
        }
    }

    return undefined;
}

export function saveStudioToken(orgId: string, token: string): void {
    set(getStudioTokenKey(orgId), token);
}

export function saveEmbedToken(contentId: string, token: string): void {
    const k = getEmbedTokenKey(contentId);
    console.log("save embed token with key ", k, token);
    set(k, token);
}

export function getEmbedToken(contentId: string): string | undefined {
    const token = get(getEmbedTokenKey(contentId));
    if (token && validToken(token)) {
        return token;
    }
    return undefined;
}

export function validToken(token: string): boolean {
    if (token) {
        try {
            if (isTokenExpired(token)) {
                throw new Error("token is expired");
            }
            return true;
        } catch (err) {
            return false;
        }
    }
    return false;
}
