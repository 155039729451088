export type Region = "eu" | "us";

export type Environment = "development" | "edge" | "staging" | "production";

export interface EnvConfig {
    studioPlayerUrls: { [key: string]: string };
    graphqlEndpoints: { [key: string]: string };
    firebase: {
        [key: string]: {
            apiKey: string;
            authDomain: string;
            projectId: string;
        };
    };
    authFrontendUrl: string;
    authServiceUrl: string;
    environment: Environment;
    playerRequireActionTimeFirstRound: number;
    playerRequireActionTime: number;
    playerLogPlayingStatusTime: number;
}

export type Config = EnvConfig;

function readEnvConfig(): EnvConfig {
    const studioPlayerUrls = {
        eu: process.env.CLIENT_APP_STUDIO_PLAYER_URL_EU as string,
        us: process.env.CLIENT_APP_STUDIO_PLAYER_URL_US as string
    };
    const graphqlEndpoints = {
        eu: process.env.CLIENT_APP_GRAPHQL_ENDPOINT_EU as string,
        us: process.env.CLIENT_APP_GRAPHQL_ENDPOINT_US as string
    };
    const firebase = {
        eu: {
            apiKey: process.env.CLIENT_APP_FIREBASE_API_KEY_EU as string,
            authDomain: process.env.CLIENT_APP_FIREBASE_AUTH_DOMAIN_EU as string,
            projectId: process.env.CLIENT_APP_FIREBASE_PROJECT_ID_EU as string
        },
        us: {
            apiKey: process.env.CLIENT_APP_FIREBASE_API_KEY_US as string,
            authDomain: process.env.CLIENT_APP_FIREBASE_AUTH_DOMAIN_US as string,
            projectId: process.env.CLIENT_APP_FIREBASE_PROJECT_ID_US as string
        }
    };

    return {
        studioPlayerUrls,
        authFrontendUrl: process.env.CLIENT_APP_AUTH_FRONTEND_URL as string,
        authServiceUrl: process.env.CLIENT_APP_AUTH_SERVICE_URL as string,
        graphqlEndpoints,
        environment: process.env.CLIENT_APP_ENVIRONMENT as Environment,
        firebase,
        playerRequireActionTimeFirstRound: +(process.env
            .CLIENT_APP_PLAYER_REQUIRE_ACTION_TIMEOUT_FIRST_ROUND as string),
        playerRequireActionTime: +(process.env.CLIENT_APP_PLAYER_REQUIRE_ACTION_TIMEOUT as string),
        playerLogPlayingStatusTime: +(process.env
            .CLIENT_APP_PLAYER_LOGGING_PLAYING_INTERVAL as string)
    };
}

const config: Config = {
    ...readEnvConfig()
};

export default config;
