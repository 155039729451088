/* eslint-disable */
/**
 * this file is entirely copied from @screencloud/signage-firestore-client
 * todo: use original library when issue solved https://github.com/screencloud/studio-embed-player/issues/24
 */

import * as firebase from "firebase/app";
import "firebase/firestore";
import {
    CollectionSubscriptionCallback,
    EntitySubscriptionCallback,
    EntityType,
    IEntityUpdateSnapshot,
    ILiveUpdateClient,
    UnsubscribeCallback
} from "./types";

export interface IFirestoreClientOptions {
    firebase: {
        apiKey: string;
        authDomain: string;
        projectId: string;
    };
    organizationId: string;
    /**
     * An internal identifier for an underlying firebase app instance.
     * Is necessary when you are going to manage several instances.
     */
    projectName?: string;
}

export class FirestoreClient implements ILiveUpdateClient {
    public organizationId: string;

    public get projectName(): string {
        return this.firebaseAppInstance?.name || "";
    }

    public readonly firestore?: firebase.default.firestore.Firestore;
    private readonly firebaseAppInstance?: firebase.default.app.App;

    constructor(options: IFirestoreClientOptions) {
        if (firebase.default.apps.length === 0) {
            this.firebaseAppInstance = firebase.default.initializeApp(
                options.firebase,
                options.projectName
            );
            this.firestore = firebase.default.firestore(
                this.firebaseAppInstance
            );
        }

        this.organizationId = options.organizationId;
    }

    public subscribeToEntityChange(
        entityType: EntityType,
        entityId: string,
        subscriptionCallback: EntitySubscriptionCallback,
        skipInitialCallback = true
    ): UnsubscribeCallback {
        const callback = this.getOnSnapshotCallback(
            subscriptionCallback,
            skipInitialCallback
        );

        return this.firestore
            ?.collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .doc(entityId)
            .onSnapshot(callback);
    }

    public subscribeToCollectionChange(
        entityType: EntityType,
        subscriptionCallback: CollectionSubscriptionCallback,
        skipInitialCallback = true
    ): UnsubscribeCallback {
        const callback = this.getOnSnapshotCallback(
            subscriptionCallback,
            skipInitialCallback
        );

        return this.firestore
            ?.collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .onSnapshot(callback);
    }

    public unsubscribeFromEntityChange(
        entityType: EntityType,
        entityId: string
    ) {
        const unsubscribe = this.firestore
            ?.collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .doc(entityId)
            .onSnapshot(() => null);

        unsubscribe && unsubscribe();
    }

    public unsubscribeFromCollectionChange(entityType: EntityType) {
        const unsubscribe = this.firestore
            ?.collection("organisations")
            .doc(this.organizationId)
            .collection(entityType)
            .onSnapshot(() => null);

        unsubscribe && unsubscribe();
    }

    public isPurged(): boolean {
        return (
            firebase.default.apps.findIndex(
                app => app.name === this.firebaseAppInstance?.name
            ) === -1
        );
    }

    public purge(): Promise<any> | undefined {
        return this.firebaseAppInstance?.delete();
    }

    private getOnSnapshotCallback(
        callbackFunction: EntitySubscriptionCallback,
        skipInitialCallback: boolean
    ): EntitySubscriptionCallback {
        /**
         * This skipInitialCallback is needed because .onSnapshot() is not just subscribing to firestore document
         * update, it's calling the callback first time right after you invoked .onSnapshot() with the current state of
         * the document. We want to be able to skip that initial call, just subscribe to future updates
         */
        return skipInitialCallback
            ? (() => {
                  let isInitialCall = true;

                  return (snapshot: IEntityUpdateSnapshot) => {
                      if (isInitialCall) {
                          isInitialCall = false;
                          return;
                      }

                      return callbackFunction(snapshot);
                  };
              })()
            : callbackFunction;
    }
}
