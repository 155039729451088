import { genUuid } from "@screencloud/uuid";
import { get, set } from "./storeManager";

let sessionId: string | undefined = undefined;

function viewerIdStorageKey(): string {
    return `embed_viewer_id`;
}

/**
 * Viewer id is stored per browser (persisted long term)
 */
export function clientEmbedViewerId(): string {
    let viewerId = get(viewerIdStorageKey());

    if (!viewerId) {
        viewerId = genUuid();
        set(viewerIdStorageKey(), viewerId);
    }

    return viewerId;
}

/**
 * Session id is stored per app launch (gone when the tab is closed)
 */
export function clientSessionId(): string {
    if (sessionId === undefined) {
        sessionId = genUuid();
    }

    return sessionId;
}
