import { createContext } from "preact";
import { RootState, WithDispatch } from "./types";
import { initialState } from "./reducer";

const rootContext = createContext<RootState & WithDispatch>({
    ...initialState,
    dispatch: () => {
        return;
    }
});

export default rootContext;
