import { EmbedInfo } from "./types";

export const updateEmbedInfoActionType = "updateEmbedInfo";
// export const updateTokenActionType = "updateTokens";

export type UpdateEmbedInfoAction = {
    type: typeof updateEmbedInfoActionType;
    payload: EmbedInfo & { embedId: string };
};

export const updateEmbedInfo = (
    embedInfo: EmbedInfo & { embedId: string }
): UpdateEmbedInfoAction => {
    return {
        type: updateEmbedInfoActionType,
        payload: embedInfo
    };
};

// export type Tokens = {
//     embedToken?: string;
//     studioToken?: string;
// };
//
// export type UpdateTokensAction = {
//     type: typeof updateTokenActionType;
//     payload: Tokens;
// };
//
// export const updateTokens = (tokens: Tokens): UpdateTokensAction => {
//     return {
//         type: updateTokenActionType,
//         payload: tokens
//     };
// };
