import { Auth } from "@screencloud/auth-sdk";
import { UUID } from "@screencloud/uuid";
import { getLogger } from "../utils/logger";

import config, { Region } from "../config";
import { getEmbedTokenFromServer, getStudioTokenFromServer } from "../api";

import { handleError } from "../utils/helper";

import {
    saveStudioToken,
    removeStudioToken,
    saveEmbedToken,
    removeEmbedToken
} from "./authStorageManager";

const log = getLogger("auth-manager");

let auth: Auth | null = null;

function getAuthSdkInstance(): Auth {
    if (auth === null) {
        auth = new Auth({
            frontend: {
                url: config.authFrontendUrl
            },
            service: {
                url: config.authServiceUrl
            }
        });
    }

    return auth;
}

export async function getAuthToken(): Promise<string | undefined> {
    try {
        const session = await getAuthSdkInstance().get();

        if (!session) {
            return undefined;
        } else {
            const { claims, token } = session;
            // sanity
            if (!claims) {
                handleError("claims cant be null if token is set");
            } else if (!token) {
                handleError("token cant be null if claims is set");
            } else if (!token.startsWith("ey")) {
                handleError("token must be jwt-shaped");
            }
            log(`logged in as ${claims.email} token: ${token}`);
            return token;
        }
    } catch (err) {
        // todo
    }
}

export async function requestStudioTokenAndSave(
    embedId: string,
    authToken: string,
    orgId: UUID,
    region: Region,
    clientEmbedViewerId: string,
    clientSessionId: string
): Promise<string | null> {
    const studioToken = await getStudioTokenFromServer(
        orgId,
        region,
        authToken,
        clientEmbedViewerId,
        clientSessionId
    );

    if (studioToken.data) {
        saveStudioToken(orgId, studioToken.data);
        return studioToken.data;
    } else {
        removeStudioToken(orgId);
        await getAuthSdkInstance().logout();
        return null;
    }
}

export async function requestEmbedTokenAndSave(
    contentType: string,
    contentId: string,
    orgId: UUID,
    studioToken: string,
    region: Region,
    clientEmbedViewerId: string,
    clientSessionId: string
): Promise<string | null> {
    const embedToken = await getEmbedTokenFromServer(
        contentType,
        contentId,
        orgId,
        studioToken,
        region,
        clientEmbedViewerId,
        clientSessionId
    );

    const token = embedToken?.data?.token;

    if (token) {
        saveEmbedToken(contentId, token);
        return token;
    } else {
        removeEmbedToken(contentId);
        return null;
    }
}
