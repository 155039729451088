import { FunctionalComponent, h } from "preact";

import * as styles from "./styles.css";

const Loading: FunctionalComponent = () => {
    return (
        <div className={styles.loader}>
            <div className="loader">Loading...</div>
        </div>
    );
};

export default Loading;
