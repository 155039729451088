import store from "store";

export function get(key: string): string | undefined {
    return store.get(key);
}

export function has(key: string): boolean {
    const value = get(key);
    return value !== undefined && value !== null && value.length > 0;
}

export function set(key: string, value: string): void {
    store.set(key, value);
}

export function remove(key: string): void {
    console.log(`remove storage key ${key}`);
    store.remove(key);
}

export function clear(): void {
    store.clearAll();
}
