import { FunctionComponent, h, RenderableProps } from "preact";

import * as styles from "./styles.css";
import PageContainer from "../PageContainer";

interface Props {
    title: string;
    topic: string;
    message?: string;
}

const ErrorPage: FunctionComponent<Props> = (props: RenderableProps<Props>) => {
    return (
        <PageContainer title={props.title || "Error"} withLogo={true}>
            <div className={styles.errorPage}>
                <h1>{props.topic}</h1>
                {props.topic && <h3>{props.message}</h3>}
            </div>
        </PageContainer>
    );
};

export default ErrorPage;
