import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
// import useRetry from "./useRetry";
import { ApiResponse } from "../../api/types";

export type ApiCaller<T> = () => Promise<ApiResponse<T>>;

export type ApiHookResponse<T> = {
    response: ApiResponse<T>;
    reload: () => void;
};

const useApiRequest = <T>(callerFunction: ApiCaller<T>): ApiHookResponse<T> => {
    const [response, setResponse] = useState<ApiResponse<T>>({});
    // const hasError = response.error !== undefined;
    const makeRequestAttempt = useCallback(() => {
        return callerFunction()
            .then(response => {
                if (!response.error) {
                    setResponse(response);
                } else {
                    setResponse({ error: { message: `${response.error}` } });
                }
            })
            .catch(err => {
                setResponse({ error: { message: err.message } });
            });
    }, [callerFunction]);

    // useRetry(hasError, makeRequestAttempt);

    useEffect(() => {
        makeRequestAttempt();
    }, [makeRequestAttempt]);

    return useMemo(
        () => ({
            response,
            reload: makeRequestAttempt
        }),
        [response, makeRequestAttempt]
    );
};

export default useApiRequest;
