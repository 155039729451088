import { FunctionComponent, h, RenderableProps, Fragment } from "preact";
import { Helmet } from "react-helmet";

import logo from "../../assets/images/sc-dark-theme.png";
import * as styles from "./styles.css";

interface Props {
    title?: string;
    withLogo?: boolean;
    backdrop?: boolean;
}

const PageContainer: FunctionComponent<Props> = ({
    title,
    withLogo,
    children,
    backdrop
}: RenderableProps<Props>) => {
    return (
        <Fragment>
            {title && <Helmet title={title} />}
            <div className={`${styles.page} ${backdrop ? styles.backdrop : ""}`}>
                {children ? (
                    <div className={styles.content}>
                        {children}
                        {withLogo && <img className={styles.logo} src={logo} alt="logo" />}
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};

export default PageContainer;
