import { ApiResponse, EmbedInfoResponse, LoggingActions } from "./types";
import config, { Region } from "../config";
import { UUID } from "@screencloud/uuid";
import ip from "ip";

// GET:  https://studio-api.dev.next.sc:5000/embed/<embed_id>
export async function getEmbedInfoFromServer(
    embedId: string,
    region: Region,
    clientEmbedViewerId: string,
    clientSessionId: string
): Promise<ApiResponse<EmbedInfoResponse>> {
    const apiEndpoint = `${config.graphqlEndpoints[region]}/embed/${embedId}`;
    const headers: HeadersInit = {
        Accept: "application/json",
        "Content-Type": "application/json",
        embed_viewer_id: clientEmbedViewerId,
        session_id: clientSessionId
    };
    const reqOptions: RequestInit = { headers: headers };

    try {
        const response = await fetch(apiEndpoint, reqOptions);
        // https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
        // status code 200-299 is true, other is false
        if (!response.ok) {
            handleError(`Fetch error: ${response.status}, ${response.statusText}`);
        }
        const data = await response.json();
        return { data: data };
    } catch (e) {
        return { error: e.message || "unknown" };
    }
}

export async function getEmbedTokenFromServer(
    contentType: string,
    contentId: string,
    orgId: string,
    studioToken: string,
    region: Region,
    clientEmbedViewerId: string,
    clientSessionId: string
): Promise<ApiResponse<EmbedInfoResponse>> {
    const apiEndpoint = `${config.graphqlEndpoints[region]}/embed/${contentType}/${orgId}/${contentId}`;
    const headers: HeadersInit = {
        Accept: "application/json",
        Authorization: `Bearer ${studioToken}`,
        "Content-Type": "application/json",
        embed_viewer_id: clientEmbedViewerId,
        session_id: clientSessionId
    };
    const reqOptions: RequestInit = {
        headers: headers,
        method: "POST",
        mode: "cors"
    };

    try {
        const response = await fetch(apiEndpoint, reqOptions);
        if (!response.ok) {
            handleError(`Fetch error: ${response.status}, ${response.statusText}`);
        }
        const data = await response.json();
        return { data: data };
    } catch (e) {
        return { error: e.message || "unknown" };
    }
}

function handleError(message: string) {
    throw new Error(message);
}

// POST:  https://studio-api.dev.next.sc:5000/auth
// get studio token will expired in 30min
export async function getStudioTokenFromServer(
    orgId: UUID,
    region: Region,
    authAccessToken: string,
    clientEmbedViewerId: string,
    clientSessionId: string
): Promise<ApiResponse<string>> {
    if (!authAccessToken) {
        return { error: new Error(`No auth access token was provided.`) };
    }
    const apiEndpoint = `${config.graphqlEndpoints[region]}/auth`;
    const headers: HeadersInit = {
        Accept: "application/json",
        Authorization: `Bearer ${authAccessToken}`,
        "Content-Type": "application/json",
        embed_viewer_id: clientEmbedViewerId,
        session_id: clientSessionId
    };
    const reqOptions: RequestInit = {
        headers: headers,
        method: "POST",
        mode: "cors"
    };

    try {
        const response = await fetch(apiEndpoint, reqOptions);
        if (!response.ok) {
            throw new Error(`Fetch error: ${response.status}, ${response.statusText}`);
        }
        const data = await response.json();
        const accessTokens = data?.accessTokens.filter(
            (d: { tokenPayload?: { org_id: string } } | undefined) =>
                d?.tokenPayload?.org_id === orgId
        );

        if (accessTokens && accessTokens.length > 0) {
            return { data: accessTokens[0].token };
        }
        throw new Error(`Access tokens array is empty.`);
    } catch (e) {
        return { error: e.message || "unknown" };
    }
}

// POST:  https://studio-api.dev.next.sc:5000/embed/log
// body {
//   action: "authenticate" | "refresh" | "play" | "continue" | "playing",
//   client_address: <ip-address>
// }
export async function embedLogToServer(
    region: Region,
    clientSessionId: UUID,
    clientEmbedViewerId: UUID,
    action: LoggingActions,
    accessToken?: string
): Promise<ApiResponse<boolean>> {
    if (!accessToken) {
        return { error: new Error(`No access token was provided.`) };
    }

    const apiEndpoint = `${config.graphqlEndpoints[region]}/embed/log`;
    const headers: HeadersInit = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        embed_viewer_id: clientEmbedViewerId,
        session_id: clientSessionId
    };
    const reqOptions: RequestInit = {
        body: JSON.stringify({
            action,
            client_address: ip.address()
        }),
        headers: headers,
        method: "POST",
        mode: "cors"
    };

    try {
        const rawResponse = await fetch(apiEndpoint, reqOptions);
        return { data: rawResponse.ok };
    } catch (e) {
        return { error: e };
    }
}
