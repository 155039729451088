import { FunctionalComponent, h, RenderableProps } from "preact";

import * as styles from "./styles.css";
import config from "../../config";

interface DebugProps {
    data: Array<[string, string | null | undefined | boolean]>;
}

const Debug: FunctionalComponent<DebugProps> = (props: RenderableProps<DebugProps>) => {
    if (config.environment !== "development") {
        return null;
    }
    return (
        <div className={styles.debugWrapper}>
            <ul>
                {props.data.map((value, index) => (
                    <li key={`${index}`}>
                        {value[0]}:<code>{value[1]}</code>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Debug;
