import { useContext } from "preact/hooks";
import rootContext from "../context";
import { RootState } from "../types";

const useSelect = <T>(selector: (state: RootState) => T): T => {
    const rootState = useContext(rootContext);
    return selector(rootState);
};

export default useSelect;
