import { Button } from "@screencloud/screencloud-ui-components";
import { FunctionalComponent, h, RenderableProps, Fragment } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import { openWindow, WindowParams } from "../../utils/window";
import { isStandalone } from "../../utils/helper";

import { getAuthToken } from "../../classes/authManager";

import config, { Region } from "../../config";

import * as styles from "./styles.css";
import PageContainer from "../PageContainer";

interface LoginPageProps {
    embedId: string;
    region: Region;
    orgName: string;
    title: string;
}

type AuthStatus = "authenticated" | "logged-out" | "not-defined";

const LoginPage: FunctionalComponent<LoginPageProps> = (props: RenderableProps<LoginPageProps>) => {
    /**
     * what does this page do
     *
     * - check if there is an active token in local storage
     * - if no active token present, perform the login sequence with screencloud/auth-sdk
     * - otherwise - redirect to player page
     */
    const { embedId, region, orgName, title } = props;
    const [authStatus, setAuthStatus] = useState<AuthStatus>("not-defined");
    const doLogin = useCallback(() => {
        let callbackURL = `${window.location.origin}/auth/${region}/${embedId}/callback`;
        // const docClientWidth = document.body.clientWidth;
        const doAuthOnPopupWindow = !isStandalone();
        // if embed player embedded in iframe on difference ScreenCloud domain will get some error
        // like this when do auth process
        //     Refused to display 'https://accounts.google.com/' in a frame because it set 'X-Frame-Options' to 'deny'.
        // this is why we use windowPopup to do authentication on popupWindow but still on ScreenCloud domain
        if (doAuthOnPopupWindow) {
            callbackURL += "?popup";
        }
        callbackURL = encodeURIComponent(callbackURL);
        const authUrl = `${config.authFrontendUrl}?redirect=${callbackURL}`;
        if (!doAuthOnPopupWindow) {
            window.location.href = authUrl;
            return;
        }

        // embed mode (playing in iframe)
        const params: WindowParams = {
            id: "windowAuth",
            url: authUrl,
            openCenter: true,
            observeWindowClosedTimeout: 2000, // 2s
            callback: (): void => {
                window.location.reload();
            }
        };
        openWindow(params);
        return;
    }, [embedId, region]);

    useEffect(() => {
        let isDismissed = false;
        getAuthToken().then(authToken => {
            if (isDismissed) {
                return;
            }
            if (authToken) {
                setAuthStatus("authenticated");
                route(`/auth/${region}/${embedId}/callback`);
            } else {
                setAuthStatus("logged-out");
            }
        });

        return (): void => {
            isDismissed = true;
        };
    }, [embedId, region]);

    return (
        <Fragment>
            <PageContainer title={"Authentication"} withLogo={true}>
                {authStatus === "logged-out" ? (
                    <Fragment>
                        <h1 className={styles.orgName}>{orgName}</h1>
                        <h2 className={styles.contentTitle}>{title}</h2>
                        <h1 className={styles.warning}>This content requires you to log in.</h1>
                        <Button onClick={doLogin}>Login</Button>
                    </Fragment>
                ) : null}
            </PageContainer>
        </Fragment>
    );
};

export default LoginPage;
